<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Modifier Groups</h4>
                  <div class="">
                    <router-link :to="{ name: 'craetemodifier' }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New Group
                      </button>
                    </router-link>
                  </div>
                </div>

                <div class="search_section">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchModifiers()" aria-required="false"
                          autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."
                          class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <select class="form-control mt-1" @change="searchModifiers()" v-model="modifierStatus">
                        <option value="">--Select status --</option>
                        <option value="published">Published</option>
                        <option value="unpublished">Unpublished</option>
                      </select>
                    </div>

                  </div>
                </div>

              </div>
              <hr>
            </div>

            <div class="col-lg-12">
              <!--Filters-->
              <div class="filters" id="filter_bar" style="display:none">
                <div v-if="adminRoles.includes(user.role.id)">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate=true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{modSelected >1 ? modSelected + ' menus': modSelected + ' menu'}} selected
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkUpdate('publish', 1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span
                      v-else>publish</span> </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkUpdate('unpublish', 2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span> <span
                      v-else>Unpublish</span> </button>
                </div>
              </div>

              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Contains</th>
                        <th scope="col">Items using</th>
                        <th scope="col">Status</th>

                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="modifier in modifiers" :key="modifier.id" draggable="true"  @drop="onDrop($event,modifier)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,modifier)">
                        <td>
                          <input type="checkbox" :id="'modifier_' + modifier.id"
                            @click="selectModifiers(modifier.id)">
                        </td>
                        <td>
                          <router-link :to="{ name: 'updatemodifier', params: { modifierid: modifier.id } }">
                            <span class="title_text">{{ modifier.name }}</span>
                          </router-link>
                        </td>
                        <td>
                          <div v-if="modifier.choices != null">
                            <div @mouseenter="choicedetails = modifier.id" @mouseleave="choicedetails = 0">
                              <span v-for="(mod_choice, modchoiceindx) in modifier.choices.slice(0, 4)"
                                :key="modchoiceindx">
                                {{ mod_choice.item_name }}
                                <span v-if="modchoiceindx + 1 < modifier.choices.length"> , </span>
                              </span>
                              <span v-if="modifier.choices.length > 3">.. +{{ parseInt(modifier.choices.length) -
                                3
                              }}</span>
                            </div>
                            <!-- tool tip -->
                            <div class="vtooltiptext"
                              v-show="choicedetails == modifier.id && modifier.choices.length > 3">
                              <div class="row">
                                <span class="col-md-3" v-for="(mod_choicef, modchoiceindxf) in modifier.choices"
                                  :key="modchoiceindxf">
                                  {{ mod_choicef.item_name }}
                                  <!-- <span v-if="modchoiceindxf + 1 < modifier.choices.length "> , </span> -->
                                </span>
                              </div>
                            </div>
                            <!-- end -->
                          </div>
                          <span v-else> - </span>
                        </td>

                        <td>
                          <div v-if="modifier.items_using != null">
                            <div @mouseenter="itemsusingdetails = modifier.id" @mouseleave="itemsusingdetails = 0">

                              <span v-for="(mod_itemusing, itemusingmod) in modifier.items_using.slice(0, 4)"
                                :key="itemusingmod">
                                {{ mod_itemusing.item_name }}
                                <span v-if="itemusingmod + 1 < modifier.items_using.length">, </span>
                              </span>
                              <span v-if="modifier.items_using.length > 3">..+{{
                                parseInt(modifier.items_using.length)
                                  - 3
                              }}</span>
                            </div>
                            <!--  -->

                            <!-- tool-tip -->
                            <div class="vtooltiptext"
                              v-show="itemsusingdetails == modifier.id && modifier.items_using.length > 3">
                              <div class="row">
                                <span class="col-md-3"
                                  v-for="(mod_items_usingf, moditems_usingindxf) in modifier.items_using"
                                  :key="moditems_usingindxf">
                                  {{ mod_items_usingf.item_name }}
                                  <!-- <span v-if="moditems_usingindxf + 1 < modifier.items_using.length "> , </span> -->
                                </span>
                              </div>
                            </div>
                            <!-- end -->
                          </div>
                          <span v-else> - </span>
                        </td>
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button">
                              <span v-if="modifier.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="modifier.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="modifier.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type="button"
                              class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown"
                              :id="'dropdownMenuButton' + modifier.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + modifier.id">
                              <a class="dropdown-item" @click="updateStatusPerItem(modifier.id, 1)"
                                href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(modifier.id, 2)"
                                href="#">Unpublish</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" >
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
                
              </div>


            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      modSelected: 0,
      modifiers: [],
      loading: false,
      toolTip: 0,
      concatText: ["..show more"],
      choicedetails: [],
      page: 1,
      totalPages: 10,
      totalRecords: 0,
      recordsPerPage: this.$store.state.modifiersRecordsPerPage,
      methodSpinner: null,
      spinner: "<i class='fa fa-spinner fa-spin '></i>",
      modifierIds: [],
      modifierStatus: '',
      itemsusingdetails: [],
      debounce: null,
      search: '',
    }
  },
  created: async function () {
    this.getModifiers();
  },
  computed: {

  },
  methods: {
    startDrag(event, modifier) {     
      event.dataTransfer.dropEffect = 'move'      
      event.dataTransfer.effectAllowed = 'move' 
      event.dataTransfer.setData('itemId',modifier);
      // evt.dataTransfer.setData('itemID', item.id)    
    },
    onDrop(event,modifier){
      const itemD = event.dataTransfer.getData('itemId')
      console.log('item on drag storange',itemD);
      console.log('dropable item',modifier);
    },
    checkItems(type) {
            document.querySelector('#uncheck').indeterminate = true;
            if (type === 'check') {
                if (document.getElementById('check_all').checked) {
                    document.getElementById('filter_bar').style.display = 'block'
                    this.modSelected = 0;
                    for (const modifier of Object.values(this.modifiers)) {
                        this.modSelected += 1;
                        document.getElementById('modifier_' + modifier.id).checked = true
                        if (this.modifierIds.includes(modifier.id) === false) {
                            this.modifierIds.push(modifier.id);
                        }
                    }
                } else {
                    document.getElementById('filter_bar').style.display = 'none'
                    for (const modifier of Object.values(this.modifiers)) {
                        document.getElementById('modifier_' + modifier.id).checked = false
                    }
                    this.modifierIds = []
                }

            } else {
                document.getElementById('filter_bar').style.display = 'none'
                document.getElementById('check_all').checked = false
                for (const modifier of Object.values(this.modifiers)) {
                    document.getElementById('modifier_' + modifier.id).checked = false
                }
                this.modifierIds = []
            }

    },
    searchModifiers() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getModifiers();
      }, 600)
    },
    selectModifiers(modifierId) {
      if (document.getElementById('modifier_' + modifierId).checked) {
        if (this.modifierIds.includes(modifierId) === false) {
          this.modifierIds.push(modifierId);
        }
        document.getElementById('filter_bar').style.display = 'block'
      } else {
        if (this.modifierIds.indexOf(modifierId) !== -1) {
          this.modifierIds = this.modifierIds.filter((x) => x !== modifierId);
        }
        for (const modifier of Object.values(this.modifiers)) {
            if (modifier.id != modifierId && document.getElementById('modifier_' + modifier.id).checked) {
                document.getElementById('filter_bar').style.display = 'block'
                break;
            } else {
                document.getElementById('filter_bar').style.display = 'none'
            }
        }
      }

      this.modSelected = 0;
      for (const modifier of Object.values(this.modifiers)) {
          if (document.getElementById('modifier_' + modifier.id).checked) {
              this.modSelected += 1;
          }
      }

    },
    bulkUpdate(method, status) {
      this.modifierIds;
      this.updateStatus(this.modifierIds, method, status);
    },
    updateStatusPerItem(id, status) {
      const idArray = []
      if (idArray.includes(id) === false) {
        idArray.push(id)
      }
      this.updateStatus(idArray, '', status)
    },
    updateStatus(idsArray, method, status) {
      if (idsArray.length > 0) {
        this.methodSpinner = method;
        if (method === 'publish' && status === false) {
          this.methodSpinner = 'unpublish'
        }
        const payload = {
          "merchant_id": parseInt(this.merchantID),
          "ids": idsArray,
          "status": status
        };
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = this.baseUrl + "/modifier_group/status";
        this.axios.patch(url, payload, config).then((response) => {
          this.modifierIds = [];
          if (response.status === 200) {
            this.methodSpinner = null;
            this.getModifiers();
            // 
          }
        }).catch((error) => {
          this.modifierIds = [];
          this.methodSpinner = null;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        });
      }
    },
    async getModifiers() {
      this.loading = true;
      // const url = `${this.baseUrl}//modifier_group?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;

      let url = `${this.baseUrl}//modifier_group`;
      url = new URL(url);
      url.searchParams.set('page', this.page);
      url.searchParams.set('size', this.recordsPerPage);
      if (this.search !== '') {
        url.searchParams.set('query', this.search);
      }
      if (this.modifierStatus !== '') {
        url.searchParams.set('status', this.modifierStatus);
      }
      url.searchParams.set('merchant_id', this.merchantID);
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.modifiers = response.data.data;
          this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
          this.totalRecords = response.data.total_count
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }).finally(() => this.loading = false);
    },
    getItems(modifierItems) {
      const items = [];
      let itemRemaining = 0;
      for (let x = 0; x < modifierItems.length; x++) {
        items.push(modifierItems[x].item_name);
      }
      if (items.length > 3) {
        itemRemaining = parseInt(items.length) - 3;
      }
      const obj = {
        'total_items': items,
        'items': items.slice(0, 4),
        'remaining': itemRemaining
      };
      return obj;
    },
    showMore(modId) {
      if (this.choicedetails[modId] === modId) {
        this.choicedetails[modId] = 0;
        this.concatText[modId] = "show less";
        // this.concatText[0] = '';
      } else {
        this.concatText[modId] = "show more";
        this.choicedetails[modId] = modId;
      }
    },
    onPageChange(page) {
      this.modifierIds = []
      document.getElementById('check_all').checked = false;
      document.getElementById('filter_bar').style.display='none';
      this.page = page
      this.getModifiers();
    },
    onChangeRecordsPerPage() {
      const payload = {
        "type":"modifier",
        "size":this.recordsPerPage
      }
      this.$store.commit("setPageLimit",payload)
            // setPageLimit
      this.getModifiers();
      // ds
    }
  },
  mixins: [ReusableDataMixins]
}
</script>
